import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdCheckCircle } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Obrońcy sprawiedliwości
			</title>
			<meta name={"description"} content={"W Advocates for Justice nie jesteś tylko klientem – jesteś częścią naszego zaangażowania na rzecz przestrzegania sprawiedliwości i uczciwości w procesie prawnym."} />
			<meta property={"og:title"} content={"Obrońcy sprawiedliwości"} />
			<meta property={"og:description"} content={"W Advocates for Justice nie jesteś tylko klientem – jesteś częścią naszego zaangażowania na rzecz przestrzegania sprawiedliwości i uczciwości w procesie prawnym."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/666ac2cae8900d0022f35236/images/1-4.jpg?v=2024-06-13T13:46:51.722Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/666ac2cae8900d0022f35236/images/1-4.jpg?v=2024-06-13T13:46:51.722Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/666ac2cae8900d0022f35236/images/1-4.jpg?v=2024-06-13T13:46:51.722Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/666ac2cae8900d0022f35236/images/1-4.jpg?v=2024-06-13T13:46:51.722Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/666ac2cae8900d0022f35236/images/1-4.jpg?v=2024-06-13T13:46:51.722Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/666ac2cae8900d0022f35236/images/1-4.jpg?v=2024-06-13T13:46:51.722Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/666ac2cae8900d0022f35236/images/1-4.jpg?v=2024-06-13T13:46:51.722Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Header>
		<Section md-padding="70px 0 80px 0" quarkly-title="Product-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/unique-handdrawn-floral-modern-shapes.png?v=2023-04-27T21:48:23.623Z) 0 0/contain no-repeat scroll padding-box"
					padding="100px 50px 100px 50px"
					md-margin="0px 0px 50px 0px"
					md-padding="50px 50px 50px 50px"
				>
					<Image
						src="https://uploads.quarkly.io/666ac2cae8900d0022f35236/images/2-3.jpg?v=2024-06-13T13:46:51.703Z"
						display="block"
						max-width="100%"
						width="350px"
						srcSet="https://smartuploads.quarkly.io/666ac2cae8900d0022f35236/images/2-3.jpg?v=2024-06-13T13%3A46%3A51.703Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666ac2cae8900d0022f35236/images/2-3.jpg?v=2024-06-13T13%3A46%3A51.703Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666ac2cae8900d0022f35236/images/2-3.jpg?v=2024-06-13T13%3A46%3A51.703Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666ac2cae8900d0022f35236/images/2-3.jpg?v=2024-06-13T13%3A46%3A51.703Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666ac2cae8900d0022f35236/images/2-3.jpg?v=2024-06-13T13%3A46%3A51.703Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666ac2cae8900d0022f35236/images/2-3.jpg?v=2024-06-13T13%3A46%3A51.703Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666ac2cae8900d0022f35236/images/2-3.jpg?v=2024-06-13T13%3A46%3A51.703Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					padding="0px 40px 0px 40px"
					lg-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 40px 0px" font="normal 500 42px/1.2 --fontFamily-serifGaramond" sm-margin="0px 0px 30px 0px">
						Kompleksowa pomoc prawna
					</Text>
					<Text margin="0px 0px 50px 0px" font="normal 300 20px/1.5 --fontFamily-sans" color="#656b70">
						W Advocates for Justice rozumiemy złożoność wyzwań prawnych i znaczenie posiadania wsparcia eksperckiego. Nasz zespół zapewnia kompleksową obsługę prawną dostosowaną do indywidualnych potrzeb każdego klienta. Niezależnie od tego, czy zajmujesz się prawem korporacyjnym, rozstrzygasz spory rodzinne, czy też stoisz przed sądem, mamy wiedzę, która pomoże Ci przez to przejść.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						lg-align-self="center"
						md-align-self="auto"
						sm-flex-direction="column"
						sm-align-items="center"
						sm-align-self="center"
					/>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-10"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					Poznaj nasz zespół prawny
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--lightD2"
					width="60%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					W Advocates for Justice nasi prawnicy są sercem naszego sukcesu. Nasz zespół składa się z doświadczonych prawników, z których każdy specjalizuje się w różnych dziedzinach prawa, aby zapewnić Państwu kompleksowe rozwiązania prawne.
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--lightD2"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					Starsi prawnicy: Dzięki dziesięcioletniemu doświadczeniu na sali sądowej nasi prawnicy są dobrze przygotowani do prowadzenia skomplikowanych spraw w różnych jurysdykcjach.
Prawnicy zajmujący się planowaniem nieruchomości: Eksperci-doradcy, którzy pomogą Ci zarządzać majątkiem i chronić go dla przyszłych pokoleń.
Doradca prawny korporacji: Zapewnia bieżące wsparcie prawne dla przedsiębiorstw, od start-upów po dojrzałe przedsiębiorstwa.
Specjaliści w prawie rodzinnym: Zaangażowani w rozwiązywanie delikatnych problemów z dyskrecją i empatią.
Nasi prawnicy są wspierani przez solidny zespół asystentów prawnych, urzędników i specjalistów administracyjnych, których celem jest świadczenie usług na najwyższym poziomie.
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="48px"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							Prawo korporacyjne
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Zakładanie działalności: Pomoc w tworzeniu różnych struktur biznesowych, zapewniając zgodność i solidne podstawy.
Prawo umów: Sporządzanie i przeglądanie umów biznesowych w celu zabezpieczenia Twoich interesów i ułatwienia sprawnego przeprowadzania transakcji.
Rozstrzyganie sporów: Eksperckie usługi w zakresie mediacji i sporów sądowych w celu skutecznego rozwiązywania sporów biznesowych.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							Prawo rodzinne
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Postępowanie rozwodowe: Współczujące i kompetentne podejście do wszystkich aspektów rozwodu, w tym podziału majątku i alimentów.
Opieka nad dzieckiem: Koncentruje się na najlepszym interesie dzieci, zawierając sprawiedliwe i przemyślane umowy dotyczące opieki nad dziećmi.
Usługi adopcyjne: Wskazówki prawne dotyczące procesu adopcji, zapewniające spełnienie wszystkich wymogów prawnych w celu płynnego przejścia.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							Postępowanie cywilne
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Roszczenia z tytułu obrażeń ciała: Reprezentacja w sprawach dotyczących obrażeń ciała, zapewniając ofiarom godziwe odszkodowanie.
Spory dotyczące nieruchomości: Rozwiązania prawne dotyczące sporów dotyczących nieruchomości, w tym kwestii granicznych i roszczeń o odszkodowanie za szkody majątkowe.
Ochrona praw konsumentów: Rzecznictwo na rzecz praw konsumentów, pomaganie klientom w kwestionowaniu nieuczciwych praktyk i dochodzeniu odszkodowania.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="48px"
				margin="2rem 0px 0px 0px"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							Prawo własności intelektualnej
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Rejestracja znaku towarowego: Chroń tożsamość swojej marki dzięki kompleksowym usługom dotyczącym znaków towarowych, od wyszukiwania po rejestrację.
Usługi patentowe: Wytyczne dotyczące wniosków patentowych, analiz naruszeń i umów licencyjnych.
Ochrona praw autorskich: Chroń swoje oryginalne dzieła przed nieuprawnionym użyciem, zapewniając bezpieczeństwo Twoich wysiłków twórczych.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Box
						align-items="flex-start"
						display="flex"
						flex-direction="column"
						lg-max-width="720px"
						align-self="center"
					>
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							Skontaktuj się i skonsultuj
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Nie wahaj się sięgnąć po profesjonalną poradę prawną. Ten przegląd stanowi zaledwie zarys tego, co mogą zaoferować rzecznicy sprawiedliwości. W celu uzyskania bardziej szczegółowych informacji na temat naszego pełnego zakresu usług prawnych lub omówienia konkretnych potrzeb, zapraszamy do kontaktu. Nasz zespół jest gotowy udzielić Ci dostosowanego do Twoich potrzeb doradztwa i dedykowanego wsparcia. Jesteśmy tutaj, aby pomóc Ci na każdym kroku.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="space-between"
					align-self="center"
				>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							Oddani doskonałości
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							W Advocates for Justice dokładamy wszelkich starań, aby chronić Twoje interesy i zapewniać najwyższe standardy prawne. Skontaktuj się już dziś, aby skorzystać z naszej wiedzy specjalistycznej i rozpocząć swoją podróż w kierunku rozwiązania prawnego i spokoju ducha.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Header />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac2cae8900d0022f35234"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});